/*! This is spaghetti, refactor and add classes/divs liberally */
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

* {
    padding: 0;
    margin: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
    font-family: 'Raleway', Arial, Helvetica, Arial;
    font-weight: 300;
}

:root {
  --background: #F5F5F5;
  --header: #111;
  --text: #000;
  --header-text: #fff;
}

html, body {
    height: 100%;
    background-color: var(--background);
    color: var(--text);
}

.title-container {
    text-align: center;
    padding: 20px;
}

.title {
    font-size: 36px;
    color: var(--text); /* Blue text color */
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 0;
}

.content-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
}

.imageContainer {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: visible;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px;
}

.nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--header);
    height: 80px;
    width: 100%;
}

.nav-wrapper {
  float: right;
  display: flex;
  flex-direction: row;
  list-style: none;
  margin-right: 20px;
}

.nav-element {
  list-style: none;
  padding: 10px;
}

.portfolio-container {
  column-count: 6;
  padding: 0 3rem;
}

@media screen and (max-width: 1400px) {
  .portfolio-container {
    column-count: 5;
  }
}

@media screen and (max-width: 1200px) {
  .portfolio-container {
    column-count: 4;
  }
}

@media screen and (max-width: 1000px) {
  .portfolio-container {
    column-count: 3;
  }
}

@media screen and (max-width: 800px) {
  .portfolio-container {
    column-count: 2;
  }
}

@media screen and (max-width: 600px) {
  .portfolio-container {
    column-count: 1;
  }
}

.portfolio-element {
  height: auto;
  width: 100%;
  padding: 10px;
}

.nav-title {
  cursor: pointer;
  color: white;
  font-size: 35px;
  line-height: 80px;
  padding: 50px;
  font-weight: bold;
}

.landing-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
  
.profile-photo {
  max-width: 26%;
  min-width: 400px;
  margin-left: 25px;
  margin-top: 25px;
  margin-right: 20px;
  height: auto;
}

.text {
  line-height: 1.6;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-basis: 60%;
  text-align: left;
  padding-left: 20px;
  font-size: 1.25rem;
}

.title {
  font-size: 2.25rem;
  font-weight: bold;
}

.form-container {
    max-width: 400px;
    margin: 0 auto; /* Center the container horizontally */
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center; /* Center the form contents horizontally */
}

.navLink {
  display: inline-block;
  padding: 10px 20px;
  background-color: none;
  color: var(--header-text);
  text-decoration: none;
  border: var(--header-text);
  border-radius: 4px;
  transition: background-color 0.25s ease;
  transition: scale 0.1s ease;
}

.navLink:hover {
  background-color: var(--header-text);
  color: var(--header);
  transition: .3s;
}

.navLink:active {
  transform: scale(0.96);
}

.contact-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

form {
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

.form {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
}

/* Style the email and message input fields */
input[type="email"], input[type="message"] {
  width: 350px;
  margin: 5px;
  margin-left: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  background-color: #f7f7f7;
  color: #333;
}

input[type="email"] {
  width: 375px;
}

.logo {
  cursor: pointer;
  color: white;
  font-size: 35px;
  line-height: 80px;
  font-weight: bold;
}

button[type="submit"] {
  padding: 10px;
  width: 50%;
  background-color: var(--text);
  color: var(--header-text);
  margin: 20px auto;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: all 0.1s ease;
}

button[type="submit"]:active {
  transform: scale(0.96);
}

@media screen and (max-width: 1075px) {
  .landing-container {
    flex-direction: column;
  }
}

/* Media query for responsive design */
@media screen and (max-width: 1075px) {
  .nav-bar {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
  }

  .nav-wrapper {
    margin: 0 auto;
  }

  .nav-title {
    display: none;
  }

  .nav-element {
    padding: 0.5em 1em;
    font-size: 1em;
    list-style-type: none;
  }

  .profile-photo {
    min-width: 80%;
  }
}

@media (max-width: 836px) {
  .nav-element {
    font-size: 0.55em;
    margin: 10px;
  }
}

@media (max-width: 716px) {
  .nav-element {
    padding: 0.2em 0.4em;
    font-size: 0.1em;
    margin: 0px;
  }
}

@media (max-width: 430px) {
  .nav-element {
    padding: 1px 2px;
    font-size: 6px;
  }
}
  
footer {
  padding: 15px;
}

footer a {
  margin: 2px;
}

/* Project Content */

.repo-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}

.repo {
  text-align: center;
  width: 80%;
  border: 4px solid var(--text);
  border-radius: 15px;
  padding: 10px;
  padding-bottom: 20px;
  list-style-type: none;
}

.repo-title {
  font-size: 24px;
  font-weight: 500;
  color: var(--text);
  margin: 10px;
}

/* Default Link Styling */
.repo a {
  color: #006fee;
}

.repo a:visited {
  color: #006fee;
}

.repo:hover {
  box-shadow:
        0 3px 6px var(--text),
        0 3px 6px var(--text);
}

.blog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.blog-title {
  font-weight: 600;
}

.blog-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 430px) {
  .blog-section {
    font-size: 90%;
  }
}

.blog-line {
  height: 1px;
  width: 110%;
  margin: 5px 0;
  background: black;
}

.blog-post {
  text-align: center;
  width: 80%;
  border: 4px solid var(--text);
  border-radius: 15px;
  padding: 10px;
  padding-bottom: 20px;
  list-style-type: none;
}

.blog-post-title {
  font-size: 24px;
  font-weight: 500;
  color: var(--text);
  margin: 10px;
}

.blog-post-description p {
  margin-bottom: 10px;
}

/* Default Link Styling */
.blog-post a {
  color: #006fee;
}

.blog-post:visited {
  color: #006fee;
}

.blog-post:hover {
  box-shadow:
        0 3px 6px var(--text),
        0 3px 6px var(--text);
}